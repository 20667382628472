import { AppearanceTypes } from 'react-toast-notifications';
import { CLEAR_TOASTER, ToasterActionTypes, USE_TOASTER } from './actions';

interface ToasterState {
  toast: {
    message: string;
    type: AppearanceTypes;
  };
}

export const initialState: ToasterState = {
  toast: {
    message: '',
    type: 'info',
  },
};

export const toasterReducer = (
  state = initialState,
  action: ToasterActionTypes,
): ToasterState => {
  switch (action.type) {
    case USE_TOASTER:
      return {
        toast: {
          message: action.payload.message,
          type: action.payload.type,
        },
      };
    case CLEAR_TOASTER:
      return {
        toast: {
          ...state.toast,
          message: '',
        },
      };
    default:
      return state;
  }
};
