import axios from 'axios';

axios.defaults.headers.post['Content-Type'] = 'application/json';
axios.defaults.baseURL =
  process.env.NODE_ENV === 'production'
    ? 'https://api.bodzii-app.com/v1'
    : 'http://localhost:5000/v1';

// if there is a token, then make sure that the authaurization token is set
// in all of the headers that are sent from axios
export const SetAuthorizationHeader = (token: string): void => {
  axios.defaults.headers.common.Authorization = token;
};
