// Firebase App (the core Firebase SDK) is always required and
// must be listed before other Firebase SDKs
import firebase from 'firebase/app';
import 'firebase/analytics';

// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: 'AIzaSyDzKMlLeA0bB3kP8MeJ1k_eD1vU70Jqvuo',
  authDomain: 'bodzii.firebaseapp.com',
  databaseURL: 'https://bodzii.firebaseio.com',
  projectId: 'bodzii',
  storageBucket: 'bodzii.appspot.com',
  messagingSenderId: '239349195810',
  appId: '1:239349195810:web:4cadadd252c715746b967b',
  measurementId: 'G-JH9QGXY92L',
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);

firebase.analytics();
