import { AppearanceTypes } from 'react-toast-notifications';

export const CLEAR_TOASTER = 'CLEAR_TOASTER';
export const USE_TOASTER = 'USE_TOASTER';

interface ToasterActionI {
  type: typeof USE_TOASTER;
  payload: {
    message: string;
    type: AppearanceTypes;
  };
}

interface ClearToaster {
  type: typeof CLEAR_TOASTER;
}

export type ToasterActionTypes = ClearToaster | ToasterActionI;

export function clearToaster(): ToasterActionTypes {
  return {
    type: CLEAR_TOASTER,
  };
}

export function toasterAction(
  message: string,
  type: AppearanceTypes,
): ToasterActionTypes {
  return {
    type: USE_TOASTER,
    payload: {
      message,
      type,
    },
  };
}
